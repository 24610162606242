.project {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
}

.project h1 {
    /* padding-top: 50px; */
    color: black;
    font-size: 50px;
    text-align: center;
}

.project img {
    width: 600px;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;

}

.project img:hover {
    transform: scale(1.1);
}

.separatorpd {
    border-top: 1px solid black;
    margin: 50px 0;
    width: 60%;
}



.project p {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    text-align: center;
    font-size: 30px;
    color: black;
    margin: 25px;
    margin-bottom: 8rem;
    padding-top: 2rem;
    padding-left: 5rem;
    padding-right: 5rem;
}


.project svg {
    font-size: 60px;
    color: black;
    position: relative;
    top: 100px;
}


.go-back a {
    display: flex;
    color: black;
    text-decoration: none;
    font-size: 20px;
    margin-bottom: 25px;
    transition: transform 0.3s ease-in-out;
}

.go-back a:hover {
    color: gray;
    transform: scale(1.2);
}

@media only screen and (max-width: 1000px) {

    .project {
        width: 100%;
        height: 100%;
        height: calc(100vh);
    }
}


@media only screen and (max-width: 800px) {

    .project {
        width: 100%;
        height: 100%;
        height: calc(100vh);
    }

    .project h1 {
        font-size: 35px;
    }

    .project img {
        width: 300px
    }

    .project p {
        font-size: 18px;
        padding: 10px;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 375px) {

    .project {
        width: 100%;
        height: 100%;
    }

    .project h1 {
        font-size: 35px;
    }

    .project img {
        width: 350px;
    }

    .separatorpd {
        width: 80%;
    }

    .project p {
        font-size: 18px;
        padding: 2px;
        margin-bottom: 2px;
    }

    .go-back a {
        font-size: 16px;
    }
}