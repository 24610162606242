.footer {
    width: 100%;
    height: 100%;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
    margin-top: auto;
    box-sizing: border-box;
}

.socialMedia svg {
    color: white;
    margin: 20px;
    font-size: 70px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
}

.socialMedia svg:hover {
    transform: scale(1.2);
}

.footer p {
    color: white;
}

@media only screen and (max-width: 600px) {
    .footer svg {
        font-size: 50px;
    }
}