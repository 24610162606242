.navbar {
    width: 100%;
    height: 100px;
    background: black;
}

.links {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: black;
}

.navbar a {
    color: white;
    text-decoration: none;
    margin: 20px;
    font-size: 20px;
}

.toggleButton {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: black;
}

.toggleButton svg {
    font-size: 50px;
}

.toggleButton button {
    margin-right: 20px;
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
}

#open {
    height: 100vh;
    background: black;
}

#open .links {
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 100px;
}

#open a {
    width: 100px;
    text-align: center;
}

@media only screen and (max-width: 900px) {
    .navbar a {
        width: 70px;
    }

    .links {
        background: black;
    }
}

@media only screen and (max-width: 600px) {
    .toggleButton {
        display: flex;
    }

    .links {
        background: black;
    }

    #close a {
        display: none;
    }
}

@media only screen and (min-width: 600px) {
    .toggleButton {
        display: none;

    }

    .hiddenLinks {
        display: none;
    }

    .links {
        background: black;
    }

    #open {
        height: 100px;
    }

    #open .links {
        flex-direction: row;
        background: black;
    }
}