.home {
    width: 100%;
    align-items: center;
    color: black;
}

.about {
    width: 100%;
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    background-image: url('../assets/alexander-andrews-vGCErDhrc3E-unsplash.jpg');
    background-size: 95%;
    background-position: top center;
    background-color: black;
    background-repeat: no-repeat;
}

.about h2 {
    font-size: 100px;
    height: 50px;
    margin-left: 10px;
    margin-bottom: 3%;
    animation: explode 1s ease-out;
    animation-fill-mode: forwards;
}



@keyframes explode {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    50% {
        transform: scale(1.2);
        opacity: 0.8;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.about .prompt {
    font-size: 30px;
    margin-bottom: 5%;
}

.prompt svg {
    font-size: 60px;
    margin: 5px;
    color: white;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
}

.prompt svg:hover {
    transform: scale(1.2);
}

.aboutme {
    font-size: 25px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}


.aboutme h1 {
    color: black;

}

.aboutme .list {
    list-style: none;
    width: 50%;
}

.item span {
    font-size: 25px;
}

.separator {
    border-top: 1px solid black;
    margin: 50px 0;
}

.list img {
    width: 300px;
    height: 330px;
    border-radius: 50%;
    display: block;
    margin: 0 auto;
}

.list li {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
}


.cta-buttons {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.cta-buttons button {
    background-color: black;
    color: white;
    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
}

.cta-buttons button:hover {
    background-color: white;
    color: black;
    border: 1px solid black;
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-2px);
    }

    60% {
        transform: translateY(-1px);
    }
}

.cta-buttons button {
    animation: bounce 1s infinite;
}


.list .tech-icons {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 50px;
}



.list .tech-icons img {
    margin-top: 40px;
    width: 50px;
    height: 50px
}

.title-3 {

    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 30px;
    font-size: 30px;
}

@media only screen and (max-width: 600px) {
    .about h2 {
        font-size: 50px;
    }

    .about .prompt {
        margin-top: 10px;
        font-size: 20px;
        width: 80%;
    }

    .aboutme {
        text-align: center;
    }

    .list {
        padding: 0;
    }

    .list span {
        font-size: 15px;
    }

    .list img {
        width: 80%;
        max-width: 300px;
        height: auto;
    }

    .skills h2 {
        font-size: 30px;
    }

    .tech-icons img:nth-child(-n+3) {
        display: none;
    }
}

@media only screen and (max-width: 350px) {
    .about h2 {
        font-size: 50px;
    }

    .list span {
        font-size: 15px;
    }

    .separator {
        border-top: 1px solid black;
        margin: 50px 0;

    }

    .cta-buttons {
        padding: 8px;
    }

    .tech-icons img:nth-child(-n+3) {
        display: none;
    }
}