.experience {
    height: calc(100vh - 50px);
    margin-bottom: 50px;
    overflow-y: scroll;
}

.vertical-timeline-element-content {
    max-height: 170px;
}

.title {
    display: flex;
    justify-content: center;
    font-size: 50px;
    padding: 25px;
}

@media only screen and (max-width:)